import { callExternalApi } from "./external-api.service";

const apiServerUrl = 'http://localhost:6060';

export const postExercise = async (accessToken,formData) => {
  const config = {
    url: `${apiServerUrl}/api/exercise/add`,
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const getExercises = async(page) =>{
  const response = await fetch(`${apiServerUrl}/api/exercise/all/paginated/`+page);
  return response
};

export const getExercise = async (id, accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/exercise/`+id,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};
  